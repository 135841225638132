import React, { useEffect, useState } from "react";
import "./snackbar-notification.scss";

export default function SnackbarNotification({
  text = "Hey, listen!",
  duration = 5000,
  position = "bottom",
  showButton = true,
  buttonText = "Ok",
  callback,
}) {
  const [shouldFadeOut, setShouldFadeOut] = useState(false);
  const [callbackTriggerd, setCallbackTriggered] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (!callbackTriggerd) setShouldFadeOut(true);
    }, duration);
  }, [duration, callbackTriggerd]);

  return (
    <div
      className={`snackbar-notification ${
        shouldFadeOut ? "show-off" : "show-in"
      }`}
    >
      <p>{text}</p>
      <button
        style={{ display: showButton ? "block" : "none" }}
        onClick={() => {
          setShouldFadeOut(true);
          if (callback)
            setTimeout(() => {
              setCallbackTriggered(true);
              callback();
            }, 500);
        }}
      >
        {buttonText}
      </button>
    </div>
  );
}
