import React, { useState } from "react";
import { SnackbarNotificationProvider } from "../snack-notification/snackbar-notification.provider";
import SnackbarNotification from "../snack-notification/snackbar-notification";
import { AppStateProvider } from "./app.provider";

export default function App({ children }) {
  const initialState =
    typeof window === `undefined`
      ? {}
      : JSON.parse(localStorage.getItem(process.env.GATSBY_APP_TAG) ?? "{}");
  const [snackbarState, setSnackbarState] = useState({
    expiredNotification: true,
  });
  return (
    <AppStateProvider value={initialState}>
      <SnackbarNotificationProvider
        value={{
          pushNotification: (text, duration) => {
            setSnackbarState({ expiredNotification: false, text, duration });
          },
        }}
      >
        {children}
      </SnackbarNotificationProvider>
      {!snackbarState.expiredNotification && (
        <SnackbarNotification
          text={snackbarState.text}
          duration={snackbarState.duration}
          callback={() =>
            setSnackbarState((oldState) => ({
              ...oldState,
              expiredNotification: true,
            }))
          }
        ></SnackbarNotification>
      )}
    </AppStateProvider>
  );
}
