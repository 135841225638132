import React from "react";
import Layout from "./src/components/layout/layout";
import InnerNavigationBar from "./src/components/inner-navigation-bar/inner-navigation-bar";
import App from "./src/providers/app/app";
import PrivateRoute from "./src/components/private-route/private-route";
import "./src/styles/global.scss";

export const onRouteUpdate = ({ location, prevLocation }) => {};

export const wrapPageElement = ({ element, props }) => {
  const escapeLayoutPaths = [
    "/login",
    "/email-confirmation",
    "/forgot-password",
    "/reset-password",
    "/register",
  ];
  const allowedPaths = [/\/login/g, /\/register/g];
  const innerNavBarPaths = ["/settings/", "/biambideros/"];

  let page = (
    <App>
      <PrivateRoute {...props} allowedPaths={allowedPaths}>
        <Layout {...props} collapsed={false}>
          <InnerNavigationBar {...props} innerNavBarPaths={innerNavBarPaths}>
            {element}
          </InnerNavigationBar>
        </Layout>
      </PrivateRoute>
    </App>
  );

  if (escapeLayoutPaths.some((el) => props.location.pathname.includes(el))) {
    page = <App>{element}</App>;
  }

  return page;
};
