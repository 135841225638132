import React from "react";
import "./menu-item.scss";
import { Link } from "gatsby";
export default function MenuItem({ iconName, pageLink, children, isActive }) {
  return (
    <Link to={pageLink} className={`menu-item ${isActive ? "active" : ""}`}>
      <span className="material-icons">{iconName ?? "android"}</span>
      <p>{children}</p>
    </Link>
  );
}
