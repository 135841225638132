import React from "react";
import { Link } from "gatsby";
import "./inner-navigation-bar.scss";

export default function InnerNavigationBar({
  location,
  children,
  innerNavBarPaths,
}) {
  const shouldShowInnerNavBar = innerNavBarPaths.some((path) =>
    location.pathname.includes(path)
  );

  const expandPath = (...slugs) => slugs.filter((s) => s !== "");
  const subSlugs = expandPath(...location.pathname.split("/"));
  const reconstructPathFromSlug = (slug) => {
    return subSlugs.reduce(
      (previusPath, currentPath) =>
        previusPath.includes(`/${slug}`)
          ? previusPath
          : `${previusPath}/${currentPath}`,
      ""
    );
  };

  return (
    <>
      {shouldShowInnerNavBar && (
        <div className="inner-navigation-bar">
          {subSlugs.map((slug, index) => (
            <span key={`slug-${index}`}>
              {index > 0 ? " > " : ""}
              <Link to={reconstructPathFromSlug(slug)}>{slug}</Link>
            </span>
          ))}
        </div>
      )}
      {children}
    </>
  );
}
