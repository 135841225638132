import React, { useEffect, useState } from "react";
import "./layout.scss";
import MenuItem from "./menu-item/menu-item";
import { Link } from "gatsby";
import { useAppState } from "../../providers/app/app.provider";

export default function Layout({ children, location, collapsed }) {
  const appState = useAppState();
  const [isNavViewerCollapsed, setIsNavViewerCollapsed] = useState(
    collapsed ?? false
  );
  const menuItems = [
    {
      icon: "fact_check",
      title: "Reservas",
      pageLink: "/bookings",
    },
    {
      icon: "engineering",
      title: "Biambideros",
      pageLink: "/biambideros",
    },
    { icon: "featured_play_list", title: "Servicios", pageLink: "/services" },
    { icon: "group", title: "Usuarios", pageLink: "/users" },
  ];

  useEffect(() => {
    setIsNavViewerCollapsed(collapsed ?? false);
  }, [collapsed]);

  return (
    <>
      {appState.bearer_token ? (
        <div
          className={`main-layout${
            isNavViewerCollapsed ? " nav-viewer-hidden" : ""
          }`}
        >
          <header className="header-logo">
            <Link to="/">
              <img
                alt="Bnbdays Logo"
                src={
                  isNavViewerCollapsed
                    ? "/bnbdays_logo.png"
                    : "/bnbdays_logo.png"
                }
              />
            </Link>
          </header>
          <header className="header">
            <i
              aria-hidden="true"
              onClick={() => {
                setIsNavViewerCollapsed(!isNavViewerCollapsed);
              }}
            >
              <span className="material-icons">menu</span>
            </i>
            <p>{appState.username ?? appState.email}</p>
          </header>
          <div className="nav-viewer">
            <div className="divider-line"></div>
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                iconName={item.icon}
                pageLink={item.pageLink}
                isActive={location.pathname.includes(item.pageLink)}
              >
                {item.title}
              </MenuItem>
            ))}
            <div className="list-spacer"></div>
            <div className="divider-line"></div>
            <MenuItem iconName="settings" pageLink="/settings" isActive={false}>
              Ajustes
            </MenuItem>
          </div>
          <div className="main-container">{children}</div>
        </div>
      ) : (
        children
      )}
    </>
  );
}
