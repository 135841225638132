import { navigate } from "gatsby";
import { useAppState } from "../../providers/app/app.provider";
const PrivateRoute = ({ location, children, allowedPaths }) => {
  const appState = useAppState();

  if (
    !appState.bearer_token &&
    !allowedPaths.some((allowedPath) => location.pathname.match(allowedPath))
  ) {
    if (typeof window !== `undefined`) {
      navigate("/login");
    }
    return null;
  }
  return children;
};
export default PrivateRoute;
