exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-biambideros-biambidero-detail-index-js": () => import("./../../../src/pages/biambideros/biambidero-detail/index.js" /* webpackChunkName: "component---src-pages-biambideros-biambidero-detail-index-js" */),
  "component---src-pages-biambideros-index-js": () => import("./../../../src/pages/biambideros/index.js" /* webpackChunkName: "component---src-pages-biambideros-index-js" */),
  "component---src-pages-bookings-index-js": () => import("./../../../src/pages/bookings/index.js" /* webpackChunkName: "component---src-pages-bookings-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-settings-index-js": () => import("./../../../src/pages/settings/index.js" /* webpackChunkName: "component---src-pages-settings-index-js" */),
  "component---src-pages-users-index-js": () => import("./../../../src/pages/users/index.js" /* webpackChunkName: "component---src-pages-users-index-js" */)
}

